import React from 'react';
import { INTERNAL_LOCATION } from 'app-constants';
import makeSectionActionsContainer from '../factories/makeSectionActionsContainer';
import EarlierPageStore from '../stores/EarlierPageStore';

const internalLocation = INTERNAL_LOCATION.TIMELINE_EARLIER;

const Container = makeSectionActionsContainer(EarlierPageStore, internalLocation);

export default props => <Container {...props} internal_location={internalLocation} />;
