import React from 'react';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { INTERNAL_LOCATION } from 'app-constants';
import makeSectionContainer from '../factories/makePageSectionContainer';
import EarlierPageSectionActionsContainer from './EarlierPageSectionActionsContainer';
import EarlierPageStore from '../stores/EarlierPageStore';
import EarlierPageActions from '../actions/EarlierPageActions';

const actions = {
  fetchSectionFeed: EarlierPageActions.fetchSectionFeed.defer,
};

const Container = makeSectionContainer(EarlierPageStore, FeatureCapabilitiesStore, actions);

const EarlierPageSection = props => (
  <Container
    {...props}
    internalLocation={INTERNAL_LOCATION.TIMELINE_EARLIER}
    sectionActionsContainer={EarlierPageSectionActionsContainer}
  />
);

export default EarlierPageSection;
