import { propEq } from 'ramda';
import { STATUS_ERROR } from 'app-constants';
import altConnect from 'higher-order-components/altConnect';
import withRouter from 'react-router/lib/withRouter';
import {
  compose,
  branch,
  renderNothing,
  renderComponent,
  lifecycle,
  withProps,
} from '@blendle/recompose';
import AuthStore from 'stores/AuthStore';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import EarlierPageStore from '../stores/EarlierPageStore';
import { getTilesUrl, isLoadingSectionFeed, shouldFetchTiles } from '../selectors/sections';
import EarlierPageActions from '../actions/EarlierPageActions';
import EarlierPage from '../components/EarlierPage';
import SectionsPageError from '../components/SectionsPageError';
import EmptyEarlierPage from '../components/EmptyEarlierPage';

function preloadSection(sectionId) {
  const { sections, sectionFeeds } = EarlierPageStore.getState();
  const section = sections.get(sectionId);

  if (shouldFetchTiles(section, sectionFeeds)) {
    EarlierPageActions.fetchSectionFeed(section.id, getTilesUrl(section));
  }
}

function mapStateToProps({ earlierPageState, moduleNavigationState }, { route }) {
  const {
    status,
    activeFeedbackItemId,
    sectionIds,
    sectionFeeds,
    scrollPosition,
    hasEarlier,
  } = earlierPageState;
  const { activeModule } = moduleNavigationState;

  return {
    hasEarlier,
    status,
    isActive: activeModule === route.module,
    isLoadingSectionFeed: isLoadingSectionFeed(sectionIds, sectionFeeds),
    activeFeedbackItemId,
    sectionIds,
    scrollPosition,
  };
}

mapStateToProps.stores = { EarlierPageStore, ModuleNavigationStore };

function fetchEarlierPage() {
  const userId = AuthStore.getState().user.id;
  EarlierPageActions.fetchEarlierPage.defer(userId);
}

const dontHaveEarlier = propEq('hasEarlier', false);
const waitForHasEarlier = propEq('hasEarlier', undefined);
const hasError = propEq('status', STATUS_ERROR);

const enhance = compose(
  withRouter,
  altConnect(mapStateToProps),
  lifecycle({ componentDidMount: fetchEarlierPage }),
  branch(hasError, renderComponent(SectionsPageError)),
  branch(waitForHasEarlier, renderNothing),
  branch(dontHaveEarlier, renderComponent(EmptyEarlierPage)),
  withProps({ preloadSection }),
);

export default enhance(EarlierPage);
