import React, { useEffect } from 'react';
import { arrayOf, string, func, number, bool } from 'prop-types';
import resetStyles from 'higher-order-components/resetStyles';
import { INTERNAL_LOCATION } from 'app-constants';
import classNames from 'classnames';
import Analytics from 'instances/analytics';
import SegmentAnalytics from 'instances/segmentAnalytics';
import PageSectionsContainer from '../../containers/PageSectionsContainer';
import EarlierPageSectionContainer from '../../containers/EarlierPageSectionContainer';
import HeaderSection from '../HeaderSection';
import CSS from './style.scss';

const internalLocation = INTERNAL_LOCATION.TIMELINE_EARLIER;

const EarlierPage = ({
  sectionIds,
  preloadSection,
  status,
  isActive,
  isLoadingSectionFeed,
  scrollPosition,
}) => {
  const scrollToPosition = () => {
    window.scrollTo(0, scrollPosition);
  };

  useEffect(() => {
    if (isActive) {
      const properties = { internal_location: internalLocation };

      Analytics.track('View Timeline', properties);
      SegmentAnalytics.page({ pageName: 'Timeline', properties });

      scrollToPosition();
    }
  }, [isActive]);

  return (
    <div className={classNames(CSS.earlierPage, !isActive && CSS.hidden)}>
      <HeaderSection
        title="Eerder voor jou deze week"
        subtitle="Hier zie je de artikelen die de afgelopen dagen voor jou geselecteerd zijn."
      />
      <PageSectionsContainer
        sectionContainer={EarlierPageSectionContainer}
        sectionIds={sectionIds}
        preloadSection={preloadSection}
        status={status}
        isLoadingSectionFeed={isLoadingSectionFeed}
        internalLocation={internalLocation}
      />
    </div>
  );
};

EarlierPage.propTypes = {
  sectionIds: arrayOf(string).isRequired,
  preloadSection: func.isRequired,
  status: number.isRequired,
  isActive: bool.isRequired,
  scrollPosition: number,
  isLoadingSectionFeed: bool.isRequired,
};

EarlierPage.defaultProps = {
  scrollPosition: 0,
};

export const EarlierPageComponent = EarlierPage;
export default resetStyles(EarlierPage);
