import alt from 'instances/altInstance';
import SectionsPageStore from './SectionsPageStore';
import EarlierPageActions from '../actions/EarlierPageActions';

class EarlierPageStore extends SectionsPageStore {
  constructor() {
    super();

    this.bindActions(EarlierPageActions);
  }

  onFetchSectionsSuccess(data) {
    super.onFetchSectionsSuccess({ ...data, hasEarlier: true });
  }

  onFetchSectionsError(error) {
    if (error.status === 404) {
      this.setState({
        hasEarlier: false,
      });
    } else {
      super.onFetchSectionsError({ error });
    }
  }
}

export default alt.createStore(EarlierPageStore, 'EarlierPageStore');
