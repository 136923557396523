import React from 'react';
import resetStyles from 'higher-order-components/resetStyles';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';
import HeaderSection from '../HeaderSection';

const Center = styledModule.div(CSS.center);
const ImageWrapper = styledModule.div(CSS.imageWrapper);
const Video = styledModule.video(CSS.image);

export function EmptyEarlierPageComponent() {
  return (
    <div>
      <HeaderSection
        title="Hier is nu nog even niks te zien"
        subtitle="Maar je kunt vanaf morgen terecht om de beste artikelen terug te lezen."
      />
      <Center>
        <ImageWrapper>
          <Video autoPlay loop muted playsInline>
            <source src="/img/illustrations/wall-e-bra.mp4" type="video/mp4" />
          </Video>
        </ImageWrapper>
      </Center>
    </div>
  );
}

export default resetStyles(EmptyEarlierPageComponent);
