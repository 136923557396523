// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".m-sections-page .a-content{padding-top:0}.earlierPage-2q-uW{background:#fff;position:relative;flex:1}.hidden-1mJ-9{display:none}\n", ""]);
// Exports
exports.locals = {
	"earlierPage": "earlierPage-2q-uW",
	"hidden": "hidden-1mJ-9"
};
module.exports = exports;
