// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".center-Ec5nM{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100%;text-align:center}.imageWrapper-9RaN0{display:flex;justify-content:center;align-items:center;width:100%}.image-1d5iA{box-shadow:0 2px 8px rgba(0,0,0,0.3);border-radius:2px;margin:4em;width:244px;height:244px}\n", ""]);
// Exports
exports.locals = {
	"center": "center-Ec5nM",
	"imageWrapper": "imageWrapper-9RaN0",
	"image": "image-1d5iA"
};
module.exports = exports;
